import { Order, ShippingMethod } from '@commercelayer/sdk'
import { yupResolver } from '@hookform/resolvers/yup'
import { APIProvider } from '@vis.gl/react-google-maps'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ObjectSchema, boolean, mixed, object, string } from 'yup'
import { getClassNames, getTranslations } from '../../config'
import { CheckoutTranslations } from '../../config/types'
import { Dealer } from '../../hooks/use-dealers'
import { useCurrentOrder } from '../../hooks/use-order'
import { FormAddress, formAddressToApiAddress, isSameAddress } from '../../lib/address'
import { intersection, isValue } from '../../lib/collection'
import { shippingMethodType } from '../../lib/shipping-method'
import Money from '../Money'
import { Form } from '../form/Form'
import { RadioGroupField } from '../form/RadioGroupField'
import { ToggleField } from '../form/ToggleField'
import { AddressFields } from './AddressFields'
import { DealerChooser } from './DealerChooser'
import { useEstimatedShippingCost } from './ShippingCost'
import { getAddressSchema } from './schema'

type FormValues = {
  shippingMethod: string
  differentAddress: boolean
  shippingAddress?: FormAddress
  dealerId?: string
}

type Props = {
  dealers: Dealer[]
  onSubmit: (order: Order) => void
}

export const CheckoutShipment = ({ dealers, onSubmit }: Props) => {
  const { order, updateOrder } = useCurrentOrder()
  const translations = getTranslations('checkout')
  const classes = getClassNames('checkout')

  const allMethods =
    order?.shipments?.map((shipments) => shipments.available_shipping_methods).filter(isValue) ?? []

  const availableMethods = allMethods.length
    ? allMethods
        // intersection returns only the common shipping methods for each shipment
        .reduce(intersection((m) => m.id))
        .sort((a, b) =>
          shippingMethodType(a) === 'home' ? -1 : shippingMethodType(b) === 'home' ? 1 : 0
        )
    : []

  const schema = useMemo(() => getSchema(translations, availableMethods), [order])

  const selectedShippingMethod = order?.shipments?.[0]?.shipping_method

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      shippingMethod: selectedShippingMethod?.id ?? availableMethods[0]?.id ?? '',
      differentAddress: Boolean(
        selectedShippingMethod &&
          shippingMethodType(selectedShippingMethod) === 'home' &&
          order?.billing_address &&
          order.shipping_address &&
          !isSameAddress(order.billing_address, order.shipping_address)
      ),
      dealerId: order?.metadata?.dealer_id,
    },
    mode: 'onChange',
  })

  const differentAddress = form.watch('differentAddress')

  const hasDealerWarning =
    availableMethods.length === 1 && shippingMethodType(availableMethods[0]) === 'dealer'

  const [scriptLoaded, setScriptLoaded] = useState(false)

  const { setEstimatedShippingCost } = useEstimatedShippingCost()

  const shippingMethod = form.watch('shippingMethod')

  useEffect(() => {
    if (shippingMethod) {
      const method = availableMethods.find((m) => m.id === shippingMethod)!
      setEstimatedShippingCost(method.price_amount_cents)
    }
  }, [shippingMethod])

  return (
    <>
      {hasDealerWarning && (
        <div className={classes.stepShipping.dealerWarning} role="alert" aria-live="polite">
          {translations.stepShipping.dealerWarningMessage}
        </div>
      )}

      <Form
        form={form}
        translations={translations.stepShipping}
        onSubmit={async (values) => {
          if (!order) return

          const method = availableMethods.find((m) => m.id === values.shippingMethod)!

          const dealer =
            shippingMethodType(method) === 'dealer' && values.dealerId
              ? dealers.find((d) => d.id === values.dealerId)
              : null // set to null to remove dealer metadata

          return updateOrder(
            {
              id: order.id,
              ...(shippingMethodType(method) === 'home'
                ? { _shipping_address_same_as_billing: !values.differentAddress }
                : {}),
            },
            {
              shippingMethodId: values.shippingMethod,

              shippingAddressUpdate:
                values.differentAddress &&
                values.shippingAddress &&
                shippingMethodType(method) === 'home'
                  ? formAddressToApiAddress(values.shippingAddress)
                  : undefined,

              dealer,
            }
          ).then((order) => {
            if (order) {
              onSubmit(order)
            }
          })
        }}
      >
        <RadioGroupField
          label={translations.stepShipping.shippingMethod}
          name="shippingMethod"
          options={availableMethods.map((method) => ({
            value: method.id,
            className: shippingMethodType(method),
            label: (
              <>
                <strong>{method.name}</strong>
                <small>
                  {shippingMethodType(method) === 'dealer'
                    ? translations.stepShipping.availability.dealerRequiredDeliveryMessage
                    : translations.stepShipping.availability.deliveryMessage}
                </small>
                <Money
                  centAmount={method.price_amount_cents ?? 0}
                  currency={method.currency_code ?? 'EUR'}
                />
                <span />
              </>
            ),
            children:
              shippingMethodType(method) === 'dealer' ? (
                <APIProvider
                  apiKey={window.commerceConfig.googleMapsApiKey}
                  libraries={['places']}
                  onLoad={() => setScriptLoaded(true)}
                >
                  {scriptLoaded && <DealerChooser dealers={dealers} />}
                </APIProvider>
              ) : shippingMethodType(method) === 'home' ? (
                <>
                  <h3>{translations.stepShipping.shippingTitle}</h3>

                  <ToggleField
                    label={translations.stepShipping.shipToDifferent}
                    name="differentAddress"
                  />

                  {differentAddress && <AddressFields addressType="shippingAddress" form={form} />}
                </>
              ) : undefined,
          }))}
        />
      </Form>
    </>
  )
}

const getSchema = (
  translations: CheckoutTranslations,
  availableShippingMethods: ShippingMethod[]
): ObjectSchema<FormValues> =>
  object({
    shippingMethod: string()
      .oneOf(availableShippingMethods.map((m) => m.id))
      .required(translations.stepShipping.validate.selectDealer)
      .default(''),
    dealerId: string().when('shippingMethod', ([shippingMethod]) =>
      shippingMethod &&
      shippingMethodType(availableShippingMethods.find((m) => m.id === shippingMethod)!) ===
        'dealer'
        ? string().required(translations.stepShipping.validate.selectDealer)
        : string().nullable()
    ),
    differentAddress: boolean().default(false),
    shippingAddress: getAddressSchema(translations).when('differentAddress', ([differentAddress]) =>
      differentAddress ? getAddressSchema(translations).required() : mixed()
    ),
  })
